import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../components/SEO";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const MainDiv = styled.div`
  max-width: 1024px;
  margin: 24px auto;
  padding: 64px;
`;
const P = styled.p`
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 500;
  color: #3d4d69;
`;
const H1 = styled.h1`
  margin: 0 0 1.5rem;
  font-family: "Futura PT", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  text-rendering: optimizeLegibility;
  line-height: 1.25;
  font-size: 42px;
  letter-spacing: 0.42px;
  text-transform: capitalize;
`;
const StyledH2 = styled.h2`
  margin: 0 0 1.5rem;
  text-rendering: optimizeLegibility;
  font-size: 32px;
  line-height: 1.25;
`;
const StyledLi = styled.li`
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-size: 18px;
`;
const StyledLink = styled(Link)`
  color: ${(props) => (props.sameColor ? `#1f2939` : `#0000EE`)};
  text-decoration: none;
`;
const StyledA = styled.a`
  color: ${(props) => (props.sameColor ? `#1f2939` : `#0000EE`)};
  text-decoration: none;
`;

// markup
const Privacy = () => {
  return (
    <>
      <SEO
        title="Terms & Condition"
        description="The terms of services of Appscom will help you to understand the rules and regulations we have set for the users. Please read before you start using our apps."
      />
      <MainDiv>
        <H1 id="Terms-of-Use">Terms of Use</H1>

        <P>Last updated: 01 JAN 2022</P>

        <P>
          <b>Welcome to Appscom!</b>
        </P>

        <P>
          This website is operated by Appscom. Throughout the site, the terms
          “we”, “us” and “our” refer to Appscom.io. Appscom offers this website,
          including all information, services and tools available from this site
          to you, the user, conditioned upon your acceptance of all terms,
          conditions, policies and notices stated in this terms of services.
        </P>

        <P>
          You agree to comply by the following terms and conditions ("Terms of
          Service," "Terms"), including such other terms and conditions and
          policies linked herein and/or available by hyperlink, by visiting our
          site and/or purchasing something from us. These Terms of Service apply
          to all site users, including but not limited to browsers, vendors,
          consumers, merchants, and/or content contributors.
        </P>
        <P>
          Before accessing or using our website, please read these Terms of
          Service carefully. You agree to abide by these Terms of Service by
          accessing and using any element of the site. You may not visit the
          website or use any services if you do not agree to all of the terms
          and conditions of this agreement.
        </P>
        <P>
          The Terms of Service will apply to any new features or tools that are
          added to the current website or store. On this page, you will find the
          most recent and updated version of the Terms of Service at any time.
          By making updates and/or changes to our website, we reserve the right
          to update, amend, or replace any portion of these Terms of Service. It
          is your duty to check this page for updates on a regular basis.
          Following the publishing of such modifications, your continued use of
          or access to the website implies acceptance of those changes and
          inclusions.
        </P>
        <P>
          Shopify Inc. also hosts our online store. They offer an online
          e-commerce platform via which we may sell Shopify Apps, Shopify
          Themes, Shopify Products & Packages, and Shopify Services.
        </P>

        <StyledH2 id="Accessing-The-Website-Services-and-Calculator">
          Accessing the Website, Services, and{" "}
          <StyledLink href="https://appscom.io/" sameColor>
            Apps
          </StyledLink>
        </StyledH2>
        <P>
          We do reserve the right, at our sole discretion and without notice to
          you, to withdraw or modify any portion or all of the website, our
          Services, and our App(s). We will not be liable if the Website, our
          Services, or our App(s) are unavailable at any time or for any period
          due to any reason. We reserve the right to limit your access to all or
          parts of the Website, our Services, and our Apps at any time.
        </P>
        <P>
          You are responsible for making necessary arrangements for you to get
          access to our services, the website, and our App(s). You need to
          ensure that all the people who will access our services, our website,
          and our App(s) through you or your internet connection and comply with
          these Terms of Services.
        </P>
        <P>
          Without our consent, you agree not to duplicate, copy, sell, resell or
          exploit any component of the Service, use of the apps, or access to
          the Service or any contact on the website through which service is
          given.
        </P>

        <StyledH2 id="Awarding-Widget">Payments and Fees</StyledH2>
        <P>
          i. Fees for Services. You agree to pay to Appscom any fees for each
          Service you purchase or use (including any overage fees), in
          accordance with the pricing and payment terms presented to you for
          that Service or app(app). Where applicable, you will be billed using
          the billing method you select through your account management page.
        </P>
        <P>
          ii.. Subscriptions. Some of our Services are billed on a subscription
          basis (we call these “Subscriptions”). This means that you will be
          billed in advance on a recurring, periodic basis (each period is
          called a “billing cycle”). Billing cycles are typically monthly or
          annual, depending on what subscription plan you select when purchasing
          a Subscription. Your Subscription will automatically renew at the end
          of each billing cycle unless you cancel by contacting our customer
          support team. While we will be sad to see you go, you may cancel
          auto-renewal on your Subscription at any time.
        </P>

        <StyledH2 id="Advertisers">
          Awarding of a Website and App(s) License, Restrictions,
          Responsibilities, and Modifications
        </StyledH2>
        <P>
          We provide you (the "Licensee") a non-exclusive, non-transferable, and
          non-sublicensable global right and license to use and exhibit
          ourApp(s) (the "License") on your domain(s), webpage(s), or blog(s)
          ("Licensee's site(s)"). If Licensee fails to comply with any
          requirement under these Terms of Services in a timely manner, or if
          these Terms of Services are terminated, the Agreement and these Terms
          of Services will immediately and automatically end. Furthermore, with
          writing notice to Licensee, we may withdraw the License and these
          Terms of Services in whole or in part. All copies of any App(s) with
          regard to which the License and these Terms of Services are terminated
          or as we may otherwise request from now and then should be promptly
          removed, deleted, or otherwise destroyed from Licensee's site(s).
        </P>
        <P>
          Licensee may not, and may not allow a third party to, (a) decompile,
          reverse engineer, disassemble, modify, distribute, or derivative works
          or developments from any App(s) or any portion of same, or attempt to
          find any source code, protocols, or other company secrets in any
          App(s); (b) acquire or try to obtain unauthorized access to the
          company's network; (c) incorporate any App(s) into any hardware; or
          (d) incorporate (e) sell, lease, loan, distribute, transfer, or
          sublicense any App(s) or access to them, or derive income from them,
          whether for conducting business or otherwise; (f) use any App(s) in
          any unlawful manner, for any illegal purpose, or in any manner that is
          incompatible with these Terms of Services; (g) use any App(s) in a
          manner that breaches, violates, or misrepresents the company's
          intellectual property rights,(h) use data mining, robots, or similar
          data collecting or extraction methods; exhibit any App(s) on any site,
          webpage, blog, or in any other way that disparages us or is otherwise.
        </P>

        <P>
          Licensee is fully responsible for the development, maintenance, and
          operation of Licensee's site, as well as all hardware and software
          utilized therein. Similarly, Licensee is responsible for any content,
          data, materials, and information contained in or shown on it.
        </P>

        <P>
          We have the right to change or cancel any or all of our Apps,
          temporarily or permanently, with or without notice to the Licensee. If
          we use our right to change or discontinue any or all of our App(s), we
          will not be accountable to Licensee or any third party (s). If
          Licensee opposes any of these modifications, Licensee's only option is
          to stop using those App(s). Continued use of these App(s) after
          receiving notice of any such modifications signifies the Licensee's
          acceptance of such changes and satisfaction with the App(s) as
          amended. Licensee irrevocably assigns to us all right, title, and
          interest in and to Licensee Suggestions if Licensee submits
          suggestions, reviews, modifications, data, photos, text, or other
          material or content in association with these Terms of Services or any
          of our App(s) or services to us or any of our affiliates ("Licensee
          Suggestions").
        </P>
        <P>
          Licensee represents and warrants that: (a) Licensee has all required
          permissions, power, and authority (including, without limitation, all
          required third-party licencing or authorizations) to interact in the
          activity contemplated by these Terms of Services; and (ii) the site
          where Licensee incorporates any App(s) will not contain material that
          is unlawful, obscene, harmful, threatening, harassing, defamatory, or
          hateful, or that invades or violates any third party's privacy (iii)
          Licensee is solely responsible for all activity occurring on or
          through Licensee's site, and the company expressly disclaims any and
          all liability in this regard.
        </P>

        <P>
          Licensee accepts that, in exchange for using any App(s), we may
          collect generic tracking data on Licensee's or Licensee's end users'
          use of the App(s) in accordance with the Company's Privacy Policy.
        </P>
        <StyledH2 id="Disclaimer-And-Liability-Limitations">
          Advertisers
        </StyledH2>

        <P>
          Your business dealings with, or involvement in promotions of,
          advertisers found on or through appscom.io are solely between you and
          such advertiser, including orders and payments of related goods or
          services and any other terms, conditions, warranties or
          representations associated with such dealings. You accept that we are
          not responsible or liable for any loss or damage of any kind incurred
          as a result of or alleged to be incurred as a result of any such
          dealings or as a result of such advertisers' presence on appscom.io.
          You agree that we will not be accountable to you if an advertiser
          fails to supply any service or product you requested from it, and you
          agree that we will not be accountable to you for any failure by an
          advertiser to provide any service or product you ordered from it.
        </P>

        <StyledH2 id="Indemnity">Pesronal Information</StyledH2>

        <P>
          Your submission of personal information through the store, website or
          apps is governed by our Privacy Policy.
        </P>
        <StyledH2 id="Jurisdiction-and-Governing-Law">
          Disclaimer and Liability Limitations
        </StyledH2>

        <P>
          THE WEBSITE, OUR TECHNOLOGIES, AND OUR APP(S) ARE PROVIDED "AS IS" AND
          "AS AVAILABLE," WITH ALL FAULTS, AND YOUR USE OF THE WEBSITE, OUR
          FACILITIES, AND OUR APP(S) IS AT YOUR SOLE RISK. FOR THE FULLEST
          EXTENT PERMITTED BY LAW, THE COMPANY, ITS DIRECTORS, MANAGERS,
          OFFICERS, EMPLOYEES, AGENTS, AFFILIATES, AND LICENSORS (THE "COMPANY
          PARTIES") DISCLAIMS ALL WARRANTIES, EXPRESSLY Or IMPLIEDLY, IN
          CONNECTION WITH THE WEBSITE, OUR SERVICES, OUR APP(S), AND ASSUMES NO
          RESPONSIBILITY FOR ANY: (I) ERRORS, MISTAKES, QUALITY, OR INACCURACIES
          ON THE WEBSITE, OUR OFFERINGS, OR OUR APP(S); (II) TECHNICAL FAULTS
          AFFECTING THE WEBSITE, OUR SERVICES IN ANY WAY; (III) VIRUSES, BUGS,
          TROJAN, OR THE LIKE WHICH MAY BE TRANSFERRED TO OR THROUGH THE SITE,
          OUR SERVICES, AND OUR APP(S) BY ANY THIRD PARTY; (V) REDUCTION IN ANY
          MATERIAL OR FOR ANY LOSS OF ANY KIND INCURRED AS A RESULT OF THE USE
          OF OUR SERVICES, THE WEBSITE, AND OUR APP(S); (VI) INTERRUPTED,
          STOPPED OR DELAYED ACCESS OR USE OF OUR SERVICES, THE WEBSITE, , AND
          OUR APP(S) FOR ANY OCCASION; (VII) MATERIAL THAT CAN BE DOWNLOADED
          FROM THE WEBSITE; (VIII) MATERIAL AT OTHER SITES ADDED TO OR FROM THE
          WEBSITE; AND (VI) COMMUNICATIONS WITH US OR OTHER COMPANY PARTIES.
        </P>
        <P>
          NO ADVICE OR INFORMATION ACQUIRED BY YOU FROM US OR THROUGH OR FROM
          USE OF THE WEBSITE, OUR SERVICES, OR OUR APP(S) IS INTENDED TO PROVIDE
          ANY LEGAL, TAX, INVESTMENT, OR FINANCIAL PLANNING OR TO BE COMPLETE ON
          ANY SUBJECT MATTER CONTAINED THEREIN, WHETHER ORAL OR WRITTEN.
        </P>
        <P>
          YOU EXPRESSLY AGREE AND ACKNOWLEDGE THAT WE AND THE COMPANY PARTIES
          SHALL NOT BE RESPONSIBLE TO YOU FOR ANY DIRECT, SPECIAL, INCIDENTAL,
          PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, DATA,
          BUSINESS, OR PROFITS) OR THE COST OF PROCURING REPLACEMENT PRODUCTS
          ARISING OUT OF OR IN CONNECTION WITH THIS TERMS OF SERVICES. THIS
          RESTRICTION MAY NOT APPLY TO YOU BECAUSE SOME JURISDICTIONS DO NOT
          ALLOW THE LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES.
        </P>
        <StyledH2 id="indemnity">Indemnity</StyledH2>

        <P>
          YOU AGREE NOT ONLY TO INDEMNIFY US BUT ALSO ANY THIRD-PARTY CLAIMANTS
          HARMLESS FROM CLAIMS ARISING OUT OF OR RELATED IN ANY WAY WITH YOUR
          ACTIONS ON THIS SITE. THIS MAY INCLUDE ANYTHING FROM ALLEGATIONS ABOUT
          INFRINGEMENT UPON INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS
          BELONGING TO OTHERS; THUS, WE ASK THAT ALL USERS ACT RESPONSIBLY WHEN
          USING THESE SERVICES AS WELL AS COOPERATE FULLY IF CONTACTED FOR AN
          INQUIRY REGARDING POSSIBLE MISUSE ACTIVITY - INCLUDING PROVIDING
          EVIDENCE SHOULD THERE BE NEED FOR IT.
        </P>
        <StyledH2 id="jurisdiction-and-governing-law">
          Jurisdiction and Governing Law
        </StyledH2>

        <P>
          If ever a dispute arises between you and Appscom, we strongly
          encourage you to first contact us directly to seek a resolution. We
          will consider reasonable and valid requests to resolve the dispute
          through alternative dispute resolution procedures, such as mediation
          or arbitration, as alternatives to litigation.
        </P>
        <P>
          Any dispute or claim arising out of or in connection with the
          Agreement or its formation (including non-contractual disputes or
          claims) shall be governed by the laws of the Peoples Republic of
          Bangladesh. The state and/or federal courts residing in Dhaka,
          Bangladesh shall have exclusive jurisdiction over any dispute or claim
          arising out of this Agreement and/or the Service.
        </P>

        <P>
          In case of any confusion, Appscom has the sole right to interpret this
          agreement.
        </P>

        <StyledH2 id="arbitration">Arbitration</StyledH2>

        <P>
          Company reserves the right to require you to submit any disputes
          arising from these Terms of Services or your access and use of our
          Services, App(s), including those concerning their interpretation,
          violation, invalidity etc., to final arbitration under the company's
          sole discretion in accordance with the arbitration act 2001 of
          Banladesh.
        </P>

        <StyledH2 id="severability-and-waiver">
          Severability and Waiver
        </StyledH2>

        <P>
          The company's waiver of any term or condition set forth in these Terms
          of Services shall not be interpreted as a further or continuing waiver
          of that term or condition or of any other term or condition, and the
          company's failure to assert a right or availability under these Terms
          of Services.
        </P>
        <P>
          Suppose any provision of these Terms of Services is found to be
          invalid, illegal, or unenforceable by a court or other tribunal of
          competent jurisdiction for any reason. In that case, that provision
          will be eliminated or limited to the bare minimum, leaving the
          remaining provisions of the Terms of Services in full force and
          effect.
        </P>

        <StyledH2 id="overall-agreement">Overall Agreement</StyledH2>
        <P>
          The Terms of Services and our Privacy Policy represent the entire
          agreement between you and us with respect to our Services, the
          Website, and our App(s), and surpass all prior and simultaneous
          understandings, contracts, representations, and warranty claims, both
          written and oral, with respect to our Services, Website, and our
          app(s).
        </P>

        <StyledH2 id="your-comments-and-feedback">
          Your Comments and Feedback
        </StyledH2>
        <P>
          All other feedback, comments, technical support requests, and other
          communications about our Services, the Website, and our App(s) should
          be sent to us through our support mail: support@appscom.io
        </P>
      </MainDiv>
    </>
  );
};

export default Privacy;
